import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import ThankYouWrapper from "../components/ThankYouWrapper";
import CardBlog from "../components/CardBlog";

import IconClutchStars from "../images/icons/social/clutch_stars.svg";
import IconDribbble from "../images/icons/social/dribbble-white.svg";

const ThankYouPage2 = ({ data, location: { pathname } }) => {
  const latestArticle = data.recentArticles?.edges
    ? data.recentArticles?.edges[0]?.node
    : undefined;

  return (
    <Layout
      hideFooter
      footerBgClass="bg-white"
      meta={{
        seo: {
          title: "Thank you for reaching us!",
        },
        path: pathname,
      }}
    >
      <div className="thank-you-page">
        <ThankYouWrapper
          title="Thank you for contacting us!"
          description="Your information has been submitted and we will get back to you soon."
          middleBlock={
            <div className="pt-2 middle-block">
              <p className="lead mt-3 middle-description">
                Until then, feel free to check out our case studies and our
                studio profiles
              </p>
            </div>
          }
          socialLinksBlock={
            <div className="social-buttons-container">
              <a
                href="/ux-projects"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-primary action-btn social-button text-white align-items-center justify-content-center"
              >
                UX Case Studies
              </a>
              <a
                href="https://dribbble.com/adamfardstudio"
                target="_blank"
                rel="noopener noreferrer"
                className="pl-3 text-white social-button dribble"
              >
                <IconDribbble className="mr-2" />
                <span>Dribbble</span>
              </a>
              <a
                href="https://clutch.co/profile/adam-fard-ux-studio"
                target="_blank"
                rel="noopener noreferrer"
                className="social-button clutch"
              >
                <IconClutchStars className="clutch-icon m-auto" />
              </a>
            </div>
          }
          article={<CardBlog key={latestArticle.id} cardData={latestArticle} />}
        />
      </div>
    </Layout>
  );
};

export default ThankYouPage2;

export const query = graphql`
  query ThankYouConfirmationQuery {
    recentArticles: allDatoCmsArticle(
      limit: 1
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          title
          cardDescription
          cardImage {
            gatsbyImageData(width: 1024, layout: CONSTRAINED)
          }
          category {
            name
          }
          id
          slug
        }
      }
    }
  }
`;
