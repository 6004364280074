import React from "react";

import DecorationLine6 from "../images/decorations/deco-lines-6.svg";
import DecorationDots5 from "../images/decorations/deco-dots-5.svg";

const ThankYouWrapper = ({
  title,
  description,
  middleBlock,
  socialLinksBlock,
  article,
  verticalLayout = false, // New prop to control layout
}) => (
  <div className="pb-5">
    <div className="container">
      <div className={`row ${verticalLayout ? "flex-column align-items-center" : ""}`}>
        <div className={`col-12 ${verticalLayout ? "col-md-8 col-lg-6" : "col-md-6"} text-left pt-5`}>
          <DecorationLine6 className="bg-yellow mt-3 mt-md-7 mb-2" />
          <h1 className="pt-3">{title}</h1>
          <p className="lead pr-5">{description}</p>
          {middleBlock}
          {socialLinksBlock}
          <div className="decoration decoration--dots float-left mt-7 d-none d-md-inline">
            <DecorationDots5 className="bg-primary opacity-20" />
          </div>
        </div>
        <div className={`col-12 ${verticalLayout ? "col-md-8 col-lg-6" : "col-md-6"} px-3 pt-3`}>
          <div className="mb-3 text-primary-3 mt-5 mt-md-7">
            <h6>Don't forget to check out our latest post:</h6>
          </div>
          {article}
        </div>
      </div>
    </div>
  </div>
);

export default ThankYouWrapper;
